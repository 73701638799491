<template>
  <teleport v-if="isShowOverlay" to="body">
    <p-overlay @on-close="handleSmoothClose()">
      <section class="p-drawer">
        <template v-for="(item, k) in stepDetails" :key="`${item.key}-${k}`">
          <div :class="item.classes" @click="handleClickBackPanel($event, item.url)">
            <p-drawer-header
              :title="item.title"
              :description="item.description"
              :is-picker="item.headerTitleSlot?.isPicker"
              :back="Number(k) > 0 && item.back"
              :header-link="item.headerLink"
              :close="item.close"
              @on-back="handleGoPreviousPanel(k)"
              @on-close="handleSmoothClose()"
              @header-click="emit('on-header-click')"
            >
              <template v-if="item.headerTitleSlot" #title>
                <nuxt-img :src="item.headerTitleSlot.icon" width="32" height="32" class="mr-8" />
              </template>
              <template #titleAction>
                <slot :name="`${item.key}-titleAction`" />
              </template>
              <template #actions>
                <slot :name="`${item.key}-actions`" />
              </template>
            </p-drawer-header>
            <div :class="['p-drawer__content', $device.isDesktop ? 'px-32 pb-32' : 'px-16 pb-16']">
              <slot v-if="activeIndex >= Number(k)" :name="item.key" />
            </div>
          </div>
        </template>
      </section>
    </p-overlay>
  </teleport>
  <template v-else> <slot name="step1" /> </template>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-close', 'active-index-change', 'on-header-click'])
const router = useRouter()
const route = useRoute()
const step = computed(() => route.query.step)
const activeIndex = ref(0)
const show = ref(false)

const props = defineProps({
  steps: {
    type: Object,
    default: () => ({}),
  },
  backTo: {
    type: String,
    default: '',
  },
  isShowOverlay: {
    type: Boolean,
    default: true,
  },
})

const stepDetails = computed((): Record<string, any> => {
  activeIndex.value =
    step && step?.value
      ? Object.values(props.steps).findIndex((item) => item?.url === step.value)
      : 0
  if (activeIndex.value < 0) activeIndex.value = 0

  emit('active-index-change', activeIndex.value)
  return Object.entries(props.steps).map(([key, value], index) => {
    return {
      key,
      url: value.url,
      back: value.back,
      title: value.title,
      description: value.description,
      headerTitleSlot: value.headerTitleSlot,
      close: value.close,
      headerLink: value.headerLink,
      classes: [
        'p-drawer__panel',
        {
          'p-drawer__panel--behind': activeIndex.value > index,
          'p-drawer__panel--out': activeIndex.value < index || !show.value,
        },
      ],
    }
  })
})

function handleClickBackPanel(event: Event, key: string): void {
  const target = event?.target as HTMLElement
  if (target?.classList?.contains('p-drawer__panel--behind')) {
    router.push({ query: { step: key } })
  }
}

function handleSmoothClose(): void {
  show.value = false
  const drawerElement = document.querySelector('.p-drawer')
  if (drawerElement) {
    drawerElement.classList.add('drawer-closed')
    drawerElement.classList.remove('drawer-open')
  }
  setTimeout(() => {
    emit('on-close')
  }, 300)
}

function handleGoPreviousPanel(index: string): void {
  const stepsArray = Object.values(props.steps)
  const currentIndex = Number(index)
  const currentStepData = stepsArray[currentIndex]

  if (currentStepData && typeof currentStepData.onBack === 'function') {
    currentStepData.onBack()
    return
  }
  if (props.backTo) {
    router.replace({
      query: { ...route.query, step: stepsArray[Number(props.backTo) - 1]?.url },
    })
  } else {
    router.replace({
      query: { ...route.query, step: stepsArray[currentIndex - 1]?.url },
    })
  }
}

onMounted(() => {
  setTimeout(() => {
    show.value = true
    const drawerElement = document.querySelector('.p-drawer')
    if (drawerElement) {
      drawerElement.classList.add('drawer-open')
      drawerElement.classList.remove('drawer-closed')
    }
  }, 0)
})
</script>
